<template>
  <div class="activityAudit app-container merch-card">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-input
          v-model="queryParams.goods_sn"
          placeholder="请输入活动商品编号"
          clearable
          size="small"
          style="width: 250px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.goods_name"
          placeholder="请输入商品名称"
          clearable
          size="small"
          style="width: 250px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.business"
          placeholder="请输入供应商名称"
          clearable
          size="small"
          style="width: 250px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item class="minItem">
        <el-date-picker
          v-model="datelist"
          type="datetimerange"
          range-separator="至"
          start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="sign">
      <!-- 左边 -->
      <div class="rightBtn">
        <div style="display: flex">
          <div
            v-for="(item, index) in statusList"
            :key="index"
            class="defaultStyle"
            :class="queryParams.re_status === item.id ? 'opp' : ''"
            @click="StatusValue(item)"
          >
            {{ item.Name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <el-table
      border
      :data="acticommList"
      style="width: 100%"
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="480"
    >
      <el-table-column align="center" label="序号" width="70">
        <template slot-scope="scope">
          <p>{{ scope.row.index }}</p>
        </template>
      </el-table-column>
      <el-table-column label="商品图片" align="center" width="120">
        <template slot-scope="scope" v-if="scope.row.picture_url">
          <el-image
            v-if="scope.row.picture_url"
            :src="scope.row.picture_url"
            :preview-src-list="[scope.row.picture_url]"
          >
          </el-image>
          <div v-else slot="placeholder" class="image-slot">
            暂无图片<span class="dot">...</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="活动商品信息"
        width="300"
        align="center"
      >
        <template slot-scope="scope">
          <div class="infor">
            <div class="hider">商品名称：{{ scope.row.goods_name }}</div>
            <div>活动商品编码：{{ scope.row.goods_sn }}</div>
            <div class="hider">厂家：{{ scope.row.manufacturer }}</div>
            <div class="hider">单位：{{ scope.row.unit }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="价格信息" align="center">
        <template slot-scope="scope">
          <div class="infor">
            <div>售价：¥{{ scope.row.selling_price }}</div>
            <div>原价：¥{{ scope.row.settlement_price }}</div>
            <div>建议零售价：¥{{ scope.row.market_price }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="库存" prop="quantity" />
      <el-table-column align="center" label="供应商" prop="name">
        <template slot-scope="scope">
          <div>
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="状态" prop="re_status">
        <template slot-scope="scope">
          <div>
            {{ scope.row.re_status | re_status }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <span
            class="btsed"
            v-if="scope.row.re_status == 1 && allJurisdiction.activityAudit155"
            @click="Submitreview(scope.row, 'audit')"
            >审核</span
          >
          <span
            class="btsed"
            v-if="scope.row.re_status != 1 && allJurisdiction.activityAudit156"
            @click="Submitreview(scope.row, 'particulars')"
            >详情</span
          >
          <!-- <span class="btsed" @click="handlereview(scope.row)">审核记录</span> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        :current-page="queryParams.page"
        background
        @current-change="getList"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 停用弹窗 -->
    <!-- <el-dialog
      title="停用活动商品提醒"
      :visible.sync="dialogDeactivate"
      :close-on-click-modal="false"
      width="25%"
    >
      <div>
        您确定要停用当前活动商品？停用后在设置买赠换购时，将无法选择当前活动商品。
      </div>
      <div slot="footer" class="text-right" style="margin-top: -20px">
        <el-button @click="dialogDeactivate = false">取 消</el-button>
        <el-button @click="Deactivateing" class="back_green bai"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
// const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "activityAudit",
  data() {
    return {
      // 加载状态
      loading: false,
      // 总条数
      total: 1,
      datelist: [],
      //活动商品状态
      statusList: [
        { Name: "全部", id: null },
        { Name: "待审核", id: 1 },
        { Name: "审核通过", id: 2 },
        { Name: "审核驳回", id: 3 },
      ],
      //商品分类
      commstate: [],
      queryParams: {
        page: 1,
        goods_id: "", //商品名称/商品编码/生产厂家
        goods_name: "", //商品名称
        business: "", //供应商名称
        start: "", //开始时间
        end: "", //结束时间
        re_status: null, //商品状态
      },
      acticommList: [],
    };
  },
  filters: {
    re_status(v) {
      switch (v) {
        case 1:
          return "待审核";
        case 2:
          return "审核通过";
        case 3:
          return "审核驳回";
      }
    },
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.datelist = this.detailsSeach[router].datelist;
    }
    this.getList();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
  },
  methods: {
    ...mapActions(["getactivityReviewList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    async getList(v) {
      this.loading = true;
      if (v) {
        this.queryParams.page = v;
      }
      if (this.datelist && this.datelist.length > 0) {
        this.queryParams.start = this.datelist[0];
        this.queryParams.end = this.datelist[1];
      } else {
        this.queryParams.start = "";
        this.queryParams.end = "";
      }
      let res = await this.getactivityReviewList(this.queryParams);
      this.loading = false;

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.datelist = this.datelist;
      save.router = router;
      this.changeSeach(save);

      res.data.data.forEach((v, i) => {
        let index = (res.data.current_page - 1) * res.data.per_page + i + 1;
        this.$set(v, "index", index);
      });
      this.acticommList = res.data.data;
      this.total = res.data.total;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 点击标记 */
    StatusValue(item) {
      this.queryParams.re_status = item.id;
      if (this.queryParams.re_status == "") {
        this.queryParams.re_status = null;
      }
      this.queryParams.page = 1;
      this.getList();
    },
    /** 审核记录 */
    handlereview(row) {
      this.$router.push({
        path: "/approvedMemo",
        query: { id: row.id, type: "activity" },
      });
    },
    /** 审核/详情 */
    Submitreview(row, val) {
      this.$router.push({
        path: "/activityDetails",
        query: { id: row.id, type: val },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 10px;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2e3238, $alpha: 0.05);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
// ::v-deep .el-date-editor .el-range-separator{
//   padding: 0;
// }
</style>
